<template>
  <base-tile
    :title="$t('entitlements.usage')"
    :editMode="props.editMode"
    i18nPrefix="entitlements"
    :rows="state.rows"
  >
    <!-- Seats -->
    <template #[state.readSeatsSlot]>
      <td class="p-2">{{ state.seats }}</td>
    </template>
    <template #[state.editSeatsSlot]>
      <td class="edit-seats">
        <va-input
          v-model="state.seats"
          @change="
            updateEntilementSeatsField('seats', Number($event.target.value))
          "
          :rules="[v => validateSeats(parseInt(v, 10))]"
          :error-messages="$t('entitlements.seatsError')"
        />
      </td>
    </template>

    <!-- License Type -->
    <template #[state.editLicenseTypeSlot]>
      <td class="p-2">
        <va-select
          v-model="state.licenseType"
          :options="LICENSE_TYPES"
          @update:model-value="
            updateEntitlementEditField('licenseType', state.licenseType)
          "
        />
      </td>
    </template>

    <!-- LICENSE ID -->
    <template #[state.readLicenseIdSlot]>
      <td v-if="state.licenseId" class="p-2">
        <span class="identifier">{{ state.licenseId }} </span>
        <button-clipboard-copy
          :field="$t('entitlements.license.id')"
          :value="state.licenseId"
        />
      </td>
    </template>

    <!-- LICENSE KEY -->
    <template #[state.readLicenseKeySlot]>
      <td v-if="state.licenseKey" class="p-2">
        <span class="identifier">{{ state.licenseKey }} </span>
        <button-clipboard-copy
          :field="$t('entitlements.license.key')"
          :value="state.licenseKey"
        />
      </td>
    </template>

    <!-- Owner Type -->
    <template #[state.editOwnerTypeSlot]>
      <td class="p-2">
        <span>{{ state.ownerType }} </span>
        <va-button
          class="ml-2"
          flat
          size="small"
          icon="edit"
          @click="openEditOwnerModal"
        />
        <va-button
          v-if="state.ownerId"
          class="ml-2"
          flat
          size="small"
          icon="delete"
          @click="openConfirmOwnerDeletion"
        />
      </td>
    </template>

    <!-- Owner ID -->
    <template #[state.readOwnerIdSlot]>
      <td v-if="state.ownerId" class="p-2">
        <span class="identifier">{{ state.ownerId }} </span>
        <button-clipboard-copy
          :field="$t('entitlements.ownerId')"
          :value="state.ownerId"
        />
      </td>
    </template>
    <template #[state.editOwnerIdSlot]>
      <td class="p-2">
        <span>{{ state.ownerId }} </span>
        <va-button
          class="ml-2"
          flat
          size="small"
          icon="edit"
          @click="openEditOwnerModal"
        />
        <va-button
          v-if="state.ownerId"
          class="ml-2"
          flat
          size="small"
          icon="delete"
          @click="openConfirmOwnerDeletion"
        />
      </td>
    </template>

    <!-- Organization name -->
    <template #[state.orgNameSlot]>
      <td v-if="state.organization" class="pl-2">
        <span>{{ state.organization.name }} </span>
        <router-link :to="{ path: organizationLink(state.organization.id) }">
          <va-button class="ml-2" flat icon="arrow_forward" />
        </router-link>
      </td>
    </template>

    <!-- User name -->
    <template #[state.userNameSlot]>
      <td v-if="state.user" class="pl-2">
        <span>
          {{ state.user.displayName }}
        </span>
        <router-link :to="{ path: userLink(state.user.id) }">
          <va-button class="ml-2" flat icon="arrow_forward" />
        </router-link>
      </td>
    </template>
  </base-tile>

  <!-- Change owner modal -->
  <change-owner-modal
    licensingObject="entitlement"
    :isOpen="state.editOwnerModalIsOpen"
    :entityType="state.ownerType"
    @cancel="state.editOwnerModalIsOpen = false"
    @validate="validate"
  />

  <!-- Change owner modal -->
  <delete-entitlement-owner-modal
    :isOpen="state.confirmOwnerDeletion"
    @cancel="state.confirmOwnerDeletion = false"
    @validate="confirmOwnerDeletion"
  />
</template>

<style scoped>
.identifier {
  font-family: monospace;
  font-size: 0.9rem;
}
</style>

<script setup>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import BaseTile from "@/components/BaseTile.vue";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import ChangeOwnerModal from "@/components/modal/ChangeOwnerModal.vue";
import DeleteEntitlementOwnerModal from "@/components/modal/DeleteEntitlementOwnerModal.vue";
import { useI18nValue } from "@/composable";
import { LICENSE_TYPES } from "@/constants";
import { organizationLink, userLink } from "@/utils/identity";

const { t } = useI18n();
const store = useStore();

const emit = defineEmits(["endEditMode", "updateEntitlement"]);

const props = defineProps({
  editMode: {
    type: Boolean,
    required: true
  }
});

const state = reactive({
  editOwnerModalIsOpen: false,
  confirmOwnerDeletion: false,
  seats: store.state.licensing.entitlementEdit.seats,
  deactivationCount:
    store.state.licensing.entitlementEdit.license?.deactivationCount ??
    undefined,
  licenseId: store.state.licensing.entitlementEdit.license?.id,
  licenseKey: store.state.licensing.entitlementEdit.license?.key,
  licenseType: store.state.licensing.entitlementEdit.license?.type,
  activationCount:
    store.state.licensing.entitlementEdit.license?.activationCount ?? 0,
  ownerType: store.state.licensing.entitlementEdit.owner?.type,
  ownerId: store.state.licensing.entitlementEdit.owner?.id,
  organization: store.state.identity.organization,
  user: store.state.identity.user,
  rows: computed(() => {
    return [
      {
        label: t("entitlements.seats"),
        value: state.seats,
        editable: true
      },
      ...(state.deactivationCount !== undefined
        ? [
            {
              label: t("entitlements.deactivationCount"),
              value: state.deactivationCount
            }
          ]
        : []),
      {
        label: t("entitlements.license.id"),
        value: state.licenseId
      },
      {
        label: t("entitlements.license.type"),
        value: useI18nValue(state.licenseType, "licenseType")
      },
      {
        label: t("entitlements.license.key"),
        value: state.licenseKey
      },
      {
        label: t("entitlements.ownerType"),
        value: useI18nValue(state.ownerType, "entityType"),
        editable: true
      },
      {
        label: t("entitlements.ownerId"),
        value: state.ownerId,
        editable: true
      },
      ...(state.ownerType === "Organization"
        ? [
            {
              label: t("users.organizationName"),
              value: state.organization.name,
              editable: false
            }
          ]
        : []),
      ...(state.ownerType === "User"
        ? [
            {
              label: t("users.email"),
              value: state.user.email,
              editable: false
            }
          ]
        : []),
      ...(state.ownerType === "User"
        ? [
            {
              label: t("users.name"),
              value: state.user.displayName,
              editable: false
            }
          ]
        : [])
    ];
  }),
  readLicenseIdSlot: `${t("entitlements.license.id")}-value`,
  readLicenseTypeSlot: `${t("entitlements.license.type")}-value`,
  readLicenseKeySlot: `${t("entitlements.license.key")}-value`,
  readSeatsSlot: `${t("entitlements.seats")}-value`,
  editSeatsSlot: `${t("entitlements.seats")}-edit-value`,
  editOwnerTypeSlot: `${t("entitlements.ownerType")}-edit-value`,
  editLicenseTypeSlot: `${t("entitlements.license.type")}-edit-value`,
  readOwnerIdSlot: `${t("entitlements.ownerId")}-value`,
  editOwnerIdSlot: `${t("entitlements.ownerId")}-edit-value`,
  orgNameSlot: `${t("users.organizationName")}-value`,
  userNameSlot: `${t("users.name")}-value`
});

watch(
  () => props.editMode,
  (editMode, prevEditMode) => {
    // Reset state based on the store value when we cancel or confirm the edition
    if (prevEditMode === true && editMode === false) {
      state.user = store.state.identity.user;
      state.organization = store.state.identity.organization;
      state.seats = store.state.licensing.entitlementEdit.seats;
      state.seatsAssigned = store.state.licensing.entitlementEdit.seatsAssigned;
      state.allowedLicenseTypes =
        store.state.licensing.entitlementEdit.allowedLicenseTypes;
      state.offlineActivationMode =
        store.state.licensing.entitlementEdit.offlineActivationMode;
      state.allowVirtualMachines = store.state.licensing.entitlementEdit
        .allowVirtualMachines
        ? t("general.yes")
        : t("general.no");
      state.manageable = store.state.licensing.entitlementEdit.manageable
        ? t("general.yes")
        : t("general.no");
      state.ownerType = store.state.licensing.entitlementEdit.owner?.type;
      state.ownerId = store.state.licensing.entitlementEdit.owner?.id;
    }
  }
);

const openEditOwnerModal = () => {
  state.editOwnerModalIsOpen = true;
};

const openConfirmOwnerDeletion = () => {
  state.confirmOwnerDeletion = true;
};

const updateEntilementSeatsField = (key, value) => {
  const seats = parseInt(value, 10);
  if (validateSeats(seats)) {
    if (
      store.state.licensing.entitlement.manageable &&
      seats != store.state.licensing.entitlementEdit.seats
    ) {
      store.dispatch("licensing/updateEntitlementEditField", {
        key: "manageable",
        value: true
      });
    }
    updateEntitlementEditField(key, seats);
  }
};

const validateSeats = value => {
  return !isNaN(value) && value > 0 && value < 2147483647;
};

const updateEntitlementEditField = (key, value) => {
  store.dispatch("licensing/updateEntitlementEditField", { key, value });
};

const validate = () => {
  emit("updateEntitlement");
  state.editOwnerModalIsOpen = false;
  state.confirmOwnerDeletion = false;
};

const confirmOwnerDeletion = () => {
  state.ownerId = null;
  state.ownerType = null;
  store.dispatch("licensing/updateEntitlementEditField", {
    key: "ownerId",
    value: null
  });
  store.dispatch("licensing/updateEntitlementEditField", {
    key: "ownerType",
    value: null
  });
  validate();
};
</script>
